export default [
  {
    header: 'admin.navigation.products',
  },
  {
    title: 'admin.navigation.catalog',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'admin.navigation.products',
        children: [
          {
            title: 'admin.navigation.categories',
            route: { name: 'product-categories-index' },
            action: 'index',
            resource: 'categories',
          },
          {
            title: 'admin.navigation.products',
            route: 'products-index',
            action: 'index',
            resource: 'products',
          },
        ],
      },
      {
        title: 'admin.navigation.attributes',
        children: [
          {
            title: 'admin.navigation.categories',
            route: { name: 'attribute-categories-index' },
            action: 'index',
            resource: 'categories',
          },
          {
            title: 'admin.navigation.attributes',
            route: 'attributes-index',
            action: 'index',
            resource: 'attributes',
          },
        ],
      },
      {
        title: 'admin.navigation.services',
        children: [
          {
            title: 'admin.navigation.categories',
            route: { name: 'service-categories-index' },
            action: 'index',
            resource: 'categories',
          },
          {
            title: 'admin.navigation.services',
            route: 'services-index',
            action: 'index',
            resource: 'stickers',
          },
        ],
      },
      {
        title: 'admin.navigation.sales',
        children: [
          {
            title: 'admin.navigation.categories',
            route: { name: 'sale-categories-index' },
            action: 'index',
            resource: 'categories',
          },
          {
            title: 'admin.navigation.sales',
            route: 'sales-index',
            action: 'index',
            resource: 'sales',
          },
        ],
      },
      {
        title: 'admin.navigation.stickers',
        route: 'stickers-index',
        action: 'index',
        resource: 'stickers',
      },
    ],
  },
]
